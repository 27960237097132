import React, { useEffect,useContext,useState } from "react";
import { useHistory,Link  } from 'react-router-dom';
import { Button,Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import {Helmet} from "react-helmet";
import { CookieBanner } from "@palmabit/react-cookie-law";
import Context from '../../_helpers/context';
import functions from "../../_helpers/functions"

import './Lp3.scoped.css'
const lang = require('../../_helpers/lang/lang.json')

export default function Lp3() {

  const context = useContext(Context)
  const locale = context.lang
  const landing = "lp3"
  const [bottoneFinale, setBottoneFinale] = useState(context.voluum_domain);
  const [urlParams, setUrlParams] = useState(context.urlParams);


  
  const setupDomande = [
    {
      titolone:lang[locale][landing].titolo1,
      titoletto:lang[locale][landing].titoletto0,
      domanda:lang[locale][landing].domanda1,
      img: require("./img/img1.png").default,
      pulsanti:1,
      tipologia:"messaggio",
      customClass:"s1"
    },
    {
        titoletto:lang[locale][landing].titoletto3,
        domanda:lang[locale][landing].domanda2,
        pulsanti:1,
        tipologia:"messaggio",
      },
      {
        nome_domanda:lang[locale][landing].nome_domanda1,
        sottodomanda:lang[locale][landing].sottodomanda1,
        tipologia:"domandaDuePulsanti",
        img: require("./img/10.png").default,
        pulsanti:2,
      },
      {
        nome_domanda:lang[locale][landing].nome_domanda2,
        domanda:lang[locale][landing].domanda3,
        sottodomanda:lang[locale][landing].sottodomanda3,
        tipologia:"selezioni",
        img: require("./img/20.png").default,
        selezioni:[
          {
            img:require("./img/Whatsapp_logo_svg.png").default,
            descrizione:lang[locale][landing].descrizione1,
            selected:false,
          },
          {
            img:require("./img/768px-Facebook_Messenger_logo_2018.svg.png").default,
            descrizione:lang[locale][landing].descrizione2,
            selected:false,
          },
          {
            img:require("./img/telegram-app_0.png").default,
            descrizione:lang[locale][landing].descrizione3,
            selected:false,
          },
          {
            img:require("./img/Instagram_logo_2016.svg.png").default,
            descrizione:lang[locale][landing].descrizione4,
            selected:false,
          }
        ],
        pulsanti:1,
      },
      {
        nome_domanda:lang[locale][landing].nome_domanda3,
        domanda:lang[locale][landing].domanda4,
        tipologia:"domanda_checkbox",
        img: require("./img/30.png").default,
        selezioni:[
          lang[locale][landing].corpo1,,lang[locale][landing].corpo2,lang[locale][landing].corpo3,lang[locale][landing].corpo4
        ],
        pulsanti:1,
      },
      {
        nome_domanda:lang[locale][landing].nome_domanda4,
        domanda:lang[locale][landing].domanda5,
        sottodomanda:lang[locale][landing].sottodomanda2,
        tipologia:"domanda_checkbox",
        img: require("./img/40.png").default,
        selezioni:[
          lang[locale][landing].capello1,lang[locale][landing].capello2,lang[locale][landing].capello3,lang[locale][landing].capello4
        ],
        pulsanti:1,
      },
      {
        nome_domanda:lang[locale][landing].nome_domanda5,
        domanda:lang[locale][landing].domanda6,
        sottodomanda:lang[locale][landing].sottodomanda2,
        tipologia:"domanda_checkbox",
        img: require("./img/80.png").default,
        selezioni:[
         lang[locale][landing].carattere1,lang[locale][landing].carattere2,lang[locale][landing].carattere3,lang[locale][landing].carattere4,lang[locale][landing].carattere5,lang[locale][landing].carattere6
        ],
        pulsanti:1,
      },
      {
        domanda:lang[locale][landing].nome_domanda6,
        img: require("./img/100.png").default,
        tipologia:"selezioni",
        pulsanti:1,
        titoletto:lang[locale][landing].titoletto1,
        sottodomanda:lang[locale][landing].sottodomanda4,
        selezioni:[
            {
    
              descrizione:lang[locale][landing].eta1,
              selected:false,
            },
            {
              descrizione:lang[locale][landing].eta2,
              selected:false,
            },
            {
              descrizione:lang[locale][landing].eta3,
              selected:false,
            },
            {
              descrizione:lang[locale][landing].eta4,
              selected:false,
            },
          ],
      },
    
      {
        titolone:lang[locale][landing].titolone1, 
        domanda:lang[locale][landing].domanda7,
        pulsanti:1,
        tipologia:"finale",
        link:context.voluum_domain,
      },
   
   
  ]
   

    const [step, setStep] = useState(0);
    const [query, setQuery] = useState(window.location.search);
    const [domande, setDomande] = useState(setupDomande);
    const [stic, setStic] = useState(0);

    let history = useHistory();


 
    useEffect(()  => {
        document.body.classList.add('lp3');
       
    });

 


  function renderDomanda(){
    return (
      <div>
        <h1 class="titolo">Web de CITAS</h1>
         <img src={domande[step].img}/>
         {domande[step].titoletto && (<h4>{domande[step].titoletto}</h4>)}
         
       

         <p className="testoDomanda" dangerouslySetInnerHTML={{__html: domande[step].domanda}}></p>
      </div>
      )
  }


  function renderSelezioni(){

    return (<div>
      <img  src={domande[step].img}/>
      <h2 class="titolo" dangerouslySetInnerHTML={{__html: domande[step].nome_domanda}}></h2>
    
      {domande[step].domanda && (<h2 class="titolettoSelezioni" dangerouslySetInnerHTML={{__html: domande[step].domanda}}></h2>)}
      {domande[step].sottodomanda && (<h3 class="sottotitolettoSelezioni" dangerouslySetInnerHTML={{__html: domande[step].sottodomanda}}></h3>)}
      <div className="row">
      {
        domande[step].selezioni.map((value,index)=>{
          
          return (
          
            <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 itemSelezioni" onClick={() => {setSelected(index)}}>
              {value.img &&(<img className="selezione" src={value.img}/>)}
              <span className={domande[step].selezioni[index].selected ? ("descrizioneSelezioniSelected") : ("descrizioneSelezioni")}>{value.descrizione}</span>
              <span className={domande[step].selezioni[index].selected ? ("checkboxSelected visible") : ("checkboxSelected")}><FontAwesomeIcon icon={faCheckCircle} /></span>
            </div>

            
         
          )}
        )
      }
       </div>
    </div>)
  }

  function renderDomandaCheckbox(){

    return (<div className="domandaCheckbox">
     
      <img src={domande[step].img}/>
      <h2 class="titolo" dangerouslySetInnerHTML={{__html: domande[step].nome_domanda}}></h2>
      {domande[step].domanda && (<h2 class="titolettoSelezioni" dangerouslySetInnerHTML={{__html: domande[step].domanda}}></h2>)}
      {domande[step].sottodomanda && (<h3 class="sottotitolettoSelezioni" dangerouslySetInnerHTML={{__html: domande[step].sottodomanda}}></h3>)}
      <div className="row">
      <ul>
      {
        domande[step].selezioni.map((value,index)=>{
          return (
                <li><label><input type="checkbox"/><span>{value}</span></label></li>
            )}
        )
      }
        </ul>
       </div>
    </div>)
  }



  function domandaDuePulsanti(){

    return (<div className="domandaCheckbox">
      {domande[step].img && (<img src={domande[step].img}/>)}
      <h2 class="titolo" dangerouslySetInnerHTML={{__html: domande[step].nome_domanda}}></h2>
      
      {domande[step].domanda && (<h2 class="titolettoSelezioni">{domande[step].domanda}</h2>)}
      {domande[step].sottodomanda && (<h3 class="sottotitolettoSelezioni" dangerouslySetInnerHTML={{__html: domande[step].sottodomanda}}></h3>)}
      <div className="row">
      <p>{domande[step].testo}</p>
       </div>
    </div>)
  }


  function setSelected(qualeSelezioni){

    var temp = domande;
      temp[step].selezioni.map((item,index) => {
        if(index == qualeSelezioni){
          temp[step].selezioni[index].selected = !temp[step].selezioni[index].selected
        }
      })

      setDomande(temp)
      setStic(prev => prev+1)

  }

  

  function renderStepFinale(){
    return (
      <div className="ultimostep">
           {domande[step].titolone && (<h1 class="titolone">{domande[step].titolone}</h1>)}
           {domande[step].titolo_piccolo && (<h3 class="titolo_piccolo">{domande[step].titolo_piccolo}</h3>)}
           {domande[step].img && (<img src={domande[step].img}/>)}
         {domande[step].titoletto && (<h4 className="ultimostep" dangerouslySetInnerHTML={{__html: domande[step].titoletto}}></h4>)}
         <p  dangerouslySetInnerHTML={{__html: domande[step].domanda}}></p>
      </div>
      )
  }

  function renderMessaggio(){
    return (
      <div className={domande[step].customClass ? domande[step].customClass : "" }>
          {domande[step].titolone && (<h1 class="titolone">{domande[step].titolone}</h1>)}
          {domande[step].img && (<img src={domande[step].img}/>)}
          {domande[step].titolo_piccolo && (<h3 class="titolo_piccolo" dangerouslySetInnerHTML={{__html: domande[step].titolo_piccolo}}></h3>)}
          {domande[step].titoletto && (<h4 dangerouslySetInnerHTML={{__html: domande[step].titoletto}}></h4>)}
         <p  dangerouslySetInnerHTML={{__html: domande[step].domanda}}></p>
      </div>
      )
  }

  function DomandaBody(){

    if(step == Object.keys(domande).length ){
       window.location.replace('/find'+query);
    }

    if(domande[step].tipologia == "domanda"){
      return renderDomanda()
    }
    else if(domande[step].tipologia == "selezioni"){
      return renderSelezioni()
    }
    else if(domande[step].tipologia == "messaggio"){
      return renderMessaggio()
    }
    else if(domande[step].tipologia == "domanda_checkbox"){
        return renderDomandaCheckbox()
      }
    else if(domande[step].tipologia == "domandaDuePulsanti"){
        return domandaDuePulsanti()
    }
    else if(domande[step].tipologia == "finale"){
      return renderStepFinale()
    }
    
   
  }

  function StampaPulsanti(){
    if(domande[step].pulsanti == 2){
      return (<div className="contieniPulsanti">
        <Button onClick={() => { setStep(prev => prev+1) }}>{lang[locale][landing].pulsante1}</Button>
        <Button onClick={() => {setStep(prev => prev+1) }} variant="secondary">{lang[locale][landing].pulsante4}</Button> 
      </div>)
    }
    if(domande[step].pulsanti == 1 && domande[step].tipologia == "finale"){
      return (<div className="contieniPulsanti buttonStepFinale">
      <Button onClick={() => { window.location.replace(bottoneFinale); }} className="unico">{lang[locale][landing].pulsante2}</Button>
    </div>)
    }
    if(domande[step].pulsanti == 1 && domande[step].tipologia != "finale"){
      return (<div className="contieniPulsanti">
      <Button onClick={() => { setStep(prev => prev+1) }} className="unico">{lang[locale][landing].pulsante3}</Button>
    </div>)
    }
  }


  useEffect(() => {
    console.log(window.deviceFingerprint)
   try{
    if(window.deviceFingerprint){
      functions.trackUser(window.deviceFingerprint,context).then(response=>{
        if(response){
          setBottoneFinale(response)
        }
      })
    }
   }catch(e){
    console.log("Track user error - ",e)
   }  
    

  },[window.deviceFingerprint]); 

  const zoneBlacklist = ["1551783","1551785","1551776","1551775","1551782","1551773","1551770","1551781","1559361","1551771"]


	return ( <div className="mainStreamGoogle1" style={{height: window.innerHeight}}>
    <Modal
        show={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="modale_landing lp3"
        onHide={() => {return } }
      >
        <div className="contenitoreDomande">
        <Modal.Body>
            <div class="lpbody">
              <DomandaBody/>
             
            </div>
        </Modal.Body>

        <Modal.Footer>
           <StampaPulsanti/>
      
         </Modal.Footer>
         </div>
         <div className="footerDomande">
            <span></span>
            {zoneBlacklist.includes(urlParams.get('zone_id')) ? (<></>) : (
              <ul>
                <li><a href="https://www.iumeet.com/privacy">Privacy</a> -</li>
                <li><a href="https://www.iumeet.com/terms">Terms and Conditions</a> - </li>
                <li><a href="https://www.iumeet.com/cookie">Cookie</a></li>
              </ul>
            )}
           
          </div>
      </Modal>
     
   
      </div>);


}