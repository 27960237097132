import React, { useEffect,useContext,useState } from "react";
import {  Button, Modal } from 'react-bootstrap';
import { CookieBanner } from "@palmabit/react-cookie-law";
import Context from '../../_helpers/context';
import functions from "../../_helpers/functions"


import StepEta from "../../forms/form_step_eta"
import StepCity from "../../forms/form_step_city"
import StepPsw from "../../forms/form_step_psw"
import StepEmail from "../../forms/form_step_email"
import StepPsw_Email from "../../forms/form_step_psw_mail"

import './offer1.css'
const lang = require('./lang/lang.json')

export default function Offer1() {

  const context = useContext(Context)
  const locale = context.lang
  const [bottoneFinale, setBottoneFinale] = useState(context.voluum_domain);
  const [errore, setErrore] = useState({});
  
  const setupDomande = [
    {
      img:"https://www.iumeet.com/images/logo-standard.png",
      tipologia:"form",
      step:"form_step_eta",
      controllo_campo:["eta"],
      nome_domanda:lang[locale].step_eta_titolo,
      domanda:lang[locale].step_eta_sottotitolo,
      sottodomanda:lang[locale].step_eta_titoletto,
      pulsanti:1
    },
    {
      img:"https://www.iumeet.com/images/logo-standard.png",
      tipologia:"form",
      step:"form_step_city",
      controllo_campo:["city_title"],
      nome_domanda:lang[locale].step_citta_titolo,
      domanda:lang[locale].step_citta_sottotitolo,
      sottodomanda:lang[locale].step_citta_titoletto,
      pulsanti:1
    },
    {
      img:"https://www.iumeet.com/images/logo-standard.png",
      tipologia:"form",
      step:"form_step_psw_mail",
      controllo_campo:["email","password"],
      nome_domanda:lang[locale].step_email_titolo,
      domanda:lang[locale].step_email_sottotitolo,
      sottodomanda:lang[locale].step_email_titoletto,
      pulsanti:1
    }
   
    
  ]
   

    const [step, setStep] = useState(0);
    const [query, setQuery] = useState(window.location.search);
    const [domande, setDomande] = useState(setupDomande);
    const [stic, setStic] = useState(0);

    useEffect(()  => {
        document.body.classList.add('offer1');
       
    });


  function renderStepForm(){

    return (<div className="domandaCheckbox">
     
      <img src={domande[step].img}/>
      <h2 class="titolo" dangerouslySetInnerHTML={{__html: domande[step].nome_domanda}}></h2>
      <hr/>
      {domande[step].domanda && (<h2 class="titolettoSelezioni" dangerouslySetInnerHTML={{__html: domande[step].domanda}}></h2>)}
      
      {domande[step].sottodomanda && (<h3 class="sottotitolettoSelezioni" dangerouslySetInnerHTML={{__html: domande[step].sottodomanda}}></h3>)}
      
         {scegliStep()}
      
    </div>)
  }


  function scegliStep(){

  
      switch(domande[step].step) {
        case 'form_step_eta':
          return <StepEta { ... errore}/>;
        case 'form_step_city':
          return <StepCity { ... errore}/>;
        case 'form_step_psw':
          return <StepPsw { ... errore}/>;
        case 'form_step_email':
          return <StepEmail { ... errore}/>;
        case 'form_step_psw_mail':
          return <StepPsw_Email { ... errore}/>;
      
      }

  }


  const StepBody = () => {


    if(step == Object.keys(domande).length ){
       window.location.replace('/find'+query);
    }

    else if(domande[step].tipologia == "form"){
      return renderStepForm()
    }
  }


  function controllaErroriCampi(){
    var campi_errati = { ...errore }
    var check = true
    for(let i=0;i<domande[step].controllo_campo.length;i++){
      if(!context.user_form[domande[step].controllo_campo[i]]){
        campi_errati[domande[step].controllo_campo[i]] = true
        console.log(campi_errati)
        setErrore(campi_errati)
        check = false
      }else{
        campi_errati[domande[step].controllo_campo[i]] = false
        setErrore(campi_errati)
      }
    }
    
    if(!check){
      return false
    }else{
      setErrore({})
      return true
    }
    
  }


  function StampaPulsanti(){
    if(domande[step].pulsanti == 2){
      return (<div className="contieniPulsanti">
        <Button onClick={() => { setStep(prev => prev+1) }}>{lang[locale].pulsante1}</Button>
        <Button onClick={() => {setStep(prev => prev-1) }} variant="secondary">{lang[locale].pulsante4}</Button> 
      </div>)
    }
    if(domande[step].pulsanti == 1 && domande[step].tipologia == "finale"){
      return (<div className="contieniPulsanti buttonStepFinale">
      <Button onClick={() => { window.location.replace(bottoneFinale); }} className="unico">{lang[locale].pulsante2}</Button>
    </div>)
    }
    if(domande[step].pulsanti == 1 && domande[step].tipologia != "finale"){
      return (<div className="contieniPulsanti">
      <Button onClick={() => { 
        const check = controllaErroriCampi()
          if(check){
            setStep(prev => prev+1) 
          }
      }
        
      } className="unico">{lang[locale].bottone}</Button>
    </div>)
    }
    return <></>
  }


  useEffect(() => {

   try{
    if(window.deviceFingerprint){
      functions.trackUser(window.deviceFingerprint,context).then(response=>{
        if(response){
          setBottoneFinale(response)
        }
      })
    }
   }catch(e){
    console.log("Track user error - ",e)
   }  
    

  },[window.deviceFingerprint]); 



	return ( <div className="offer1" style={{height: window.innerHeight,background:'url("https://www.tuttoilmegliodelweb.com/newmultisplash/wp-content/uploads/sites/13/2022/01/Immagine-2022-01-10-124756-1.jpg")',backgroundSize:'cover'}}>
    
    <Modal
        show={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {return } }
      >
        <div className="contenitoreDomande">
        <Modal.Body>
            <div class="lpbody">
              <StepBody/>
            </div>
        </Modal.Body>
        <Modal.Footer>
           <StampaPulsanti/>
         </Modal.Footer>
         </div>
         <div className="footerDomande">
            <span></span>
            <ul>
              <li><a href="https://www.iumeet.com/privacy">Privacy</a> -</li>
              <li><a href="https://www.iumeet.com/terms">Terms and Conditions</a> - </li>
              <li><a href="https://www.iumeet.com/cookie">Cookie</a></li>
            </ul>
          </div>
      </Modal>
     
   
      </div>);


}