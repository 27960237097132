

export default {
    trackUser
}


async function trackUser(fingerprint,context){


    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const domain = window.location.hostname;
    var servizio = urlParams.get('service') ? urlParams.get('service'):null
    var zone_id = urlParams.get('zonaid') ? urlParams.get('zonaid'):null
    var campid = urlParams.get('campid') ? urlParams.get('campid'):null


    return await fetch("https://track.advdog.info/collect/lander", {
      method: 'POST',
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
      },
      body: JSON.stringify({fingerprint:fingerprint,"country": context.lang,servizio:servizio,domain:domain,zone_id:zone_id,isAdult:context.isAdult,campid:campid})
    })
    .then(response => response.json())
    .then(response => {
      if(!response.testing){
        if(response.red){
            return response.red
        }else{
            return null
        }
      }
    })
 }