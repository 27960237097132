import React, { useEffect,useContext,useState } from "react";
import Context from '../_helpers/context';

const lang = require('./lang/lang.json')


export default function InputAuto(props) {

  

    const [suggestions, setSugesstions] = useState([]);
    const [isHideSuggs, setIsHideSuggs] = useState(true);
    const [selectedVal, setSelectedVal] = useState("");
    
    const context = useContext(Context)

    const locale = context.lang
    const cities = require("../_helpers/lang/cities/city_"+locale+".json"); 
    const [data, setData] = useState(cities);

    const handler = e => {
      
      if(data.length > 0){
          const res = data.filter(i => {
            return i.city_title.toLowerCase().startsWith(selectedVal)
          })
          setSugesstions(res);
      }
    };
  
    const handleChange = e => {
      const input = e.target.value;
      setIsHideSuggs(false);
      setSelectedVal(input);
      onChange(input);
    };
  
    const hideSuggs = value => {
      onSelected(value);
      setSelectedVal(value.city_title );
      setIsHideSuggs(true);
    };


    const onSelected = value => {
      context.user_form.city_title = value.city_title
      context.user_form.city_id = value.city_id
      context.user_form.region = value.state_title
      setIsHideSuggs(true);
    };
  
    const onChange = value => {
      if(value == ""){
        context.user_form.city_title = null
        context.user_form.city_id = null
        context.user_form.region = null
        setIsHideSuggs(true);
      }

      
    };


  
    return (
      <div className="sugesstion-auto">
        <div className="form-control-auto">
          <input
            placeholder="City"
            type="search"
            value={selectedVal}
            onChange={handleChange}
            onKeyUp={handler}
          />
        </div>
  
        <div className="suggestions" style={{ display: isHideSuggs ? "none" : "inline-block" , cursor: "pointer"}}
        >
          {suggestions.map((item, idx) => (
            <div
              key={"" + item.city_title + idx}
              onClick={() => {
                hideSuggs(item);
              }}
            >
              {item.city_title}
            </div>
          ))}
        </div>
        { props.city_title && (<div className="errore">{lang[locale].errore_city}</div>)}
      </div>
    );
  }