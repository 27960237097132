import React, { useEffect,useContext,useState } from "react";
import { useHistory  } from 'react-router-dom';
import { Button,Modal } from 'react-bootstrap'
import Context from '../../_helpers/context';

import './Lp4.scoped.css'
const lang = require('../../_helpers/lang/lang.json')

export default function Lp4() {

    const context = useContext(Context)
  const locale = context.lang
  const landing = "lp4"



  return ( <div>
        <div className="mobile_center text-center">
            <img className="img_avviso" src={require("./img/labbra.png").default}></img>
            <div className="titolo_disclaimer">
                <p>Le donne registrate sono alla ricerca di incontri casuali <br/><strong>Tre semplici informazioni:</strong></p>
            </div>
            <div className="regole">
                <h3>- EMAIL VALIDA</h3>
                <p>Per provare che sei un utente reale è importante che tu inserisca una email valida perchè ti verrà chiesto di confermarla. E' obbligatorio che tutti gli utenti nel sito abbiano una <strong>email confermata.</strong></p>

                <h3>- NON PER TUTTI</h3>
                <p>Essendo un sito <strong>esclusivo</strong> per la tua zona, al momento la registrazioni di uomini sono ancora disponibili per alcuni utenti 
(Altrimenti riprova la prossima settimana).</p>

                <h3>- DISCREZIONE</h3>
                <p>Probabilmente alcune donne ti invieranno messaggi con foto di nudo, è importante che tu <strong>mantenga segreta</strong> la loro identità.</p>

                <Button onClick={() => { window.location.replace(context.voluum_domain) }} className="btn-grad">ACCETTO - PROSEGUI</Button>
            </div>
        </div>
        
  
  
    </div>);


}

