import React, { useEffect,useContext,useState } from "react";
import Context from '../_helpers/context';
const lang = require('./lang/lang.json')

export default function Eta(props) {

  const context = useContext(Context)
  const locale = context.lang

  const [valore, setValore] = useState(null);

  
  function getAges (){
    var ages = []
    for(let i=18;i<99;i++){
      ages.push(i)
    }
    return ages
  }

  function handleInputChange(target) {
    var value = target.currentTarget.value;
    if(value){
      context.user_form.eta = value
    }
    
  }




const [ages, setAges] = useState(getAges());


  return (
    <div>
        <select onChange={(e) => handleInputChange(e)}>
          <option selected disabled hidden>Età</option>
          { ages && 
              (
                  ages.map(el=>(
                    <option value={el}>{el}</option>
                      
                  ))
              ) 
          }
        </select>
        { props.eta && (<div className="errore">{lang[locale].errore_eta}</div>)}
          
    </div>
  );
}