import React, { useEffect,useContext,useState } from "react";
import { useHistory  } from 'react-router-dom';
import { Button,Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import {Helmet} from "react-helmet";

import './Lp2.scoped.css'

export default function Lp2(user) {

  const setupDomande = [
    {
      titoletto:"Importante",
      domanda:"Antes de mostrarle las&nbsp;<span style='text-decoration: underline;'>mujeres&nbsp;españolas en línea cerca de usted</span>&nbsp;tenemos que hacerle algunas preguntas, porque puede encontrar&nbsp;<span style='text-decoration: underline;'>fotos explícitas</span>.&nbsp;En este sitio encontrará amas de casa desesperadas, esposas aburridas y mujeres solteras. Queremos proteger su anonimato.",
      pulsanti:1,
      tipologia:"messaggio",
    },
    {
      titoletto:"",
      domanda:"Antes de mostrarle las <span style='text-decoration: underline;'>mujeres&nbsp;españolas en línea cerca de usted</span> tenemos que hacerle algunas preguntas, porque puede encontrar <span style='text-decoration: underline;'>fotos explícitas</span>.",
      pulsanti:1,
      tipologia:"messaggio",
    },
    {
      selezioni:[
        {

          descrizione:"Whatsapp",
          selected:false,
        },
        {
          descrizione:"Messenger",
          selected:false,
        },
        {
          descrizione:"Instagram",
          selected:false,
        },
        {
          descrizione:"Telegram",
          selected:false,
        },
      ],
      domanda:"PREGUNTA # 1: Mensajes",
      tipologia:"selezioni",
      pulsanti:1,
      titoletto:"¿Qué usas para enviar mensajes?",
      sottotitoletto:"(La mayoría de las mujeres usan WhatsApp y Facebook)",
    },
    {
      img: require("./img/casalinga.png").default,
      domanda:"Cada vez que ingrese al sitio, recibirá numerosos mensajes de mujeres de la zona. Algunas mujeres incluso te enviarán fotos de desnudos. ¿Te sientes cómodo con esta situación?",
      pulsanti:2,
      tipologia:"domanda",
    },
    {
      selezioni:[
        {

          descrizione:"Una noche",
          selected:false,
        },
        {
          descrizione:"Amistad",
          selected:false,
        },
        {
          descrizione:"Sexo regular",
          selected:false,
        },
        {
          descrizione:"Cita",
          selected:false,
        },
      ],
      domanda:"PASO #4: Objetivo",
      tipologia:"selezioni",
      pulsanti:1,
      titoletto:"¿Qué tipo de relación estás buscando?",
      sottotitoletto:"(Elija hasta 3 respuestas)",
    },
    {
      selezioni:[
        {

          descrizione:"De 18 a 25",
          selected:false,
        },
        {
          descrizione:"De 25 a 30",
          selected:false,
        },
        {
          descrizione:"De 30 a 40",
          selected:false,
        },
        {
          descrizione:"de 40 en..",
          selected:false,
        },
      ],
      domanda:"PASO # 5: Edad preferida",
      tipologia:"selezioni",
      pulsanti:1,
      titoletto:"¿Busca mujeres a qué edad?",
      sottotitoletto:"",
    },
    {
      img: require("./img/finale.png").default,
      titoletto:"¡Felicidades!",
      domanda:"<span style='background-color:yellow'>¡Eres apto para nuestro sitio!<span><br>Respuestas aprobadas!<br>¡Se encontró registro gratis!<br>Encuentra 168 mujeres que coincidan con tus gustos<br>¡Está aprobado para ingresar!",
      pulsanti:1,
      link:"https://uniallel-conary.icu/click",
      tipologia:"finale",
    }
  ]
   

    const [step, setStep] = useState(0);
    const [query, setQuery] = useState(window.location.search);
    const [domande, setDomande] = useState(setupDomande);
    const [stic, setStic] = useState(0);

    let history = useHistory();


 
    useEffect(() => {
      var scripts = [
        { url : "https://a.exoclick.com/tag_gen.js", data: {name: "data-goal",value:"f349300c9a81f952e7c3ced5ca1c5cc2"}, tipo: "script", network:"exo" },
        { url : "//tsyndicate.com/api/v1/retargeting/set/4198d2d7-a5b4-4e70-9a29-a36977f9f4e4", tipo: "image" , network: "trs" },
        { url : "https://ads.traffichunt.com/adv_ret/?adv_pixel_id=916&nid=3", tipo: "image" , network: "trhunt" }
      ]

      var element = null

      for(let i=0;i<scripts.length;i++){
          if(scripts[i].tipo == "script"){
            element = document.createElement('script')
            element.src = scripts[i].url
            if(scripts[i].data){
              element.setAttribute(scripts[i].data.name, scripts[i].data.value)
            }
            element.async = true
          }
          if(scripts[i].tipo == "image"){
            element = document.createElement('img')
            element.src = scripts[i].url
            element.width = "0"
            element.height = "0"
          }
          document.body.appendChild(element);
      }
    },[]); 

 


  function renderDomanda(){
    return (
      <div>
        <h1 class="titolo">Web de CITAS</h1>
         <img src={domande[step].img}/>
         {domande[step].titoletto && (<h4>{domande[step].titoletto}</h4>)}
         
       

         <p className="testoDomanda" dangerouslySetInnerHTML={{__html: domande[step].domanda}}></p>
      </div>
      )
  }


  function renderSelezioni(){

    return (<div>
      <h1 class="titolo">{domande[step].domanda}</h1>
      {domande[step].titoletto && (<h2 class="titolettoSelezioni">{domande[step].titoletto}</h2>)}
      {domande[step].sottotitoletto && (<h3 class="sottotitolettoSelezioni">{domande[step].sottotitoletto}</h3>)}
      <div className="row">
      {
        domande[step].selezioni.map((value,index)=>{
          
          return (
          
            <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 itemSelezioni" onClick={() => {setSelected(index)}}>
              {value.img &&(<img src={value.img}/>)}
              <span className={domande[step].selezioni[index].selected ? ("descrizioneSelezioniSelected") : ("descrizioneSelezioni")}>{value.descrizione}</span>
              <span className={domande[step].selezioni[index].selected ? ("checkboxSelected visible") : ("checkboxSelected")}><FontAwesomeIcon icon={faCheckCircle} /></span>
            </div>
         
          )}
        )
      }
       </div>
    </div>)
  }


  function setSelected(qualeSelezioni){

    var temp = domande;
      temp[step].selezioni.map((item,index) => {
        if(index == qualeSelezioni){
          temp[step].selezioni[index].selected = !temp[step].selezioni[index].selected
        }
      })

      setDomande(temp)
      setStic(prev => prev+1)

  }

  

  function renderStepFinale(){
    return (
      <div>
         <img src={domande[step].img}/>
         <h4>{domande[step].titoletto}</h4>
         <p  dangerouslySetInnerHTML={{__html: domande[step].domanda}}></p>
      </div>
      )
  }

  function renderMessaggio(){
    return (
      <div>
          <h1 class="titolo">Web de CITAS</h1>
         <h4>{domande[step].titoletto}</h4>
         <p  dangerouslySetInnerHTML={{__html: domande[step].domanda}}></p>
      </div>
      )
  }

  function DomandaBody(){

    if(step == Object.keys(domande).length ){
       window.location.replace('/find'+query);
    }

    if(domande[step].tipologia == "domanda"){
      return renderDomanda()
    }
    else if(domande[step].tipologia == "selezioni"){
      return renderSelezioni()
    }
    else if(domande[step].tipologia == "messaggio"){
      return renderMessaggio()
    }
    else if(domande[step].tipologia == "finale"){
      return renderStepFinale()
    }
   
  }

  function StampaPulsanti(){
    if(domande[step].pulsanti == 2){
      return (<div className="contieniPulsanti">
        <Button onClick={() => { setStep(prev => prev+1) }}>NO</Button>
        <Button onClick={() => {setStep(prev => prev+1) }} variant="secondary">SI</Button> 
      </div>)
    }
    if(domande[step].pulsanti == 1 && domande[step].tipologia == "finale"){
      return (<div className="contieniPulsanti buttonStepFinale">
      <Button onClick={() => { window.location.replace(domande[step].link); }} className="unico">SIGUIENTE</Button>
    </div>)
    }
    if(domande[step].pulsanti == 1 && domande[step].tipologia != "finale"){
      return (<div className="contieniPulsanti">
      <Button onClick={() => { setStep(prev => prev+1) }} className="unico">Continúa</Button>
    </div>)
    }
  }
        

	return ( <div className="lpBackgroundNoImg" style={{height: window.innerHeight}}>
   
    <Modal
        show={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="modale_landing lp2"
        onHide={() => {return } }
      >
  
        <Modal.Body>
            <div class="lpbody lp2">
              <DomandaBody/>
             
            </div>
        </Modal.Body>
        <Modal.Footer>
           <StampaPulsanti/>
          </Modal.Footer>
      </Modal>
      </div>);


}