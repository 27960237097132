import React, { useEffect,useContext,useState,useLayoutEffect } from "react";
import { useHistory  } from 'react-router-dom';
import { Button,Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import Context from '../../_helpers/context';
import functions from "../../_helpers/functions"
import {Helmet} from "react-helmet";


import './Lp1.scoped.css'
const lang = require('../../_helpers/lang/lang.json')


export default function Lp1() {
  

  const context = useContext(Context)
  const locale = context.lang
  const landing = "lp1"
  const landing_adult = true

  const [bottoneFinale, setBottoneFinale] = useState(context.voluum_domain);

  const setupDomande = [
    {
      img: require("./img/step_1_es.png").default,
      domanda:lang[locale][landing].domanda1,
      pulsanti:2,
      tipologia:"domanda",
    },
    {
      img: require("./img/step_2_es.png").default,
      domanda:lang[locale][landing].domanda2,
      pulsanti:2,
      tipologia:"domanda",
    },
    {
      img: require("./img/step_3_es.png").default,
      domanda:lang[locale][landing].domanda3,
      pulsanti:2,
      tipologia:"domanda",
    },
    {
      img: require("./img/step_4_es.png").default,
      domanda:lang[locale][landing].domanda4,
      pulsanti:2,
      tipologia:"domanda",
    },
    {
      selezioni:[
        {
          img:require("./img/corpo_1.jpg").default,
          descrizione:lang[locale][landing].corpo1,
          selected:false,
        },
        {
          img:require("./img/corpo_2.jpg").default,
          descrizione:lang[locale][landing].corpo2,
          selected:false,
        },
        {
          img:require("./img/corpo_3.jpg").default,
          descrizione:lang[locale][landing].corpo3,
          selected:false,
        },
        {
          img:require("./img/corpo_4.jpg").default,
          descrizione:lang[locale][landing].corpo4,
          selected:false,
        },
      ],
      domanda:lang[locale][landing].domanda5,
      tipologia:"selezioni",
      pulsanti:1,
    },
    {
      selezioni:[
        {
          img:require("./img/age_1.jpg").default,
          descrizione:"18-25",
          selected:false,
        },
        {
          img:require("./img/age_2.jpg").default,
          descrizione:"26-40",
          selected:false,
        },
        {
          img:require("./img/age_4.jpg").default,
          descrizione:"41 - 60",
          selected:false,
        },
        {
          img:require("./img/age_5.jpg").default,
          descrizione:"60 +",
          selected:false,
        },
      ],
      domanda:lang[locale][landing].domanda6,
      tipologia:"selezioni",
      pulsanti:1,
    },
    {
      selezioni:[
        {
          img:require("./img/pecorina.jpg").default,
          descrizione:lang[locale][landing].descrizione1,
          selected:false,
        },
        {
          img:require("./img/sopra.jpg").default,
          descrizione:lang[locale][landing].descrizione2,
          selected:false,
        },
        {
          img:require("./img/missionario.jpg").default,
          descrizione:lang[locale][landing].descrizione3,
          selected:false,
        },
        {
          img:require("./img/pompino.jpg").default,
          descrizione:lang[locale][landing].descrizione4,
          selected:false,
        },
      ],
      domanda:lang[locale][landing].domanda7,
      tipologia:"selezioni",
      pulsanti:1,
    },
    {
      selezioni:[
        {
          img:require("./img/social_1.jpg").default,
          descrizione:"Whatsapp",
          selected:false,
        },
        {
          img:require("./img/social_3.jpg").default,
          descrizione:"Messenger",
          selected:false,
        },
        {
          img:require("./img/social_22.jpg").default,
          descrizione:"Instagram",
          selected:false,
        },
        {
          img:require("./img/social_4.jpg").default,
          descrizione:"Telegram",
          selected:false,
        },
      ],
      domanda:lang[locale][landing].domanda8,
      tipologia:"selezioni",
      pulsanti:1,
    },
    {
      img: require("./img/step_5_es.png").default,
      titoletto:lang[locale][landing].titoletto1,
      domanda:lang[locale][landing].testo1,
      pulsanti:1,
      link:bottoneFinale,
      tipologia:"finale",
    },
  ]
   

    const [step, setStep] = useState(0);
    const [query, setQuery] = useState(window.location.search);
    const [domande, setDomande] = useState(setupDomande);
    const [stic, setStic] = useState(0);

    let history = useHistory();

    useEffect(() => {
      waitForCovery()
    },[]);


   /* useEffect(() => {
      console.log(window.deviceFingerprint)
  try{
   if(window.deviceFingerprint){
     functions.trackUser(window.deviceFingerprint,context).then(response=>{
       if(response){
         console.log("setto - ", response)
         setBottoneFinale(response)
       }
     })
   }
  }catch(e){
   console.log("Track user error - ",e)
  }  
   },[window.deviceFingerprint]); */
   

  async function waitForCovery(){
    let promise = new Promise((res, rej) => {
			var intervalID = setInterval(() => {
        console.log("attendo")
				if (localStorage.getItem('deviceFingerprint') !== undefined) {
          functions.trackUser(localStorage.getItem('deviceFingerprint'),context).then(response=>{
            if(response){
              console.log("setto - ", response)
              setBottoneFinale(response)
              
            }
          })
					res(intervalID);
				}
			}, 2000);
		});

    let intervalID = await promise;
    clearInterval(intervalID);
  }
 
  


  function renderDomanda(){
    return (
      <div>
        <h2 class="titolo" dangerouslySetInnerHTML={{__html: lang[locale][landing].titolo1}}></h2>
         <img src={domande[step].img}/>
         {domande[step].titoletto && (<h4>{domande[step].titoletto}</h4>)}
         
         <p className="text_center pregunta">{lang[locale][landing].parola1}  
            {domande.map((value,index) => {
                if(index==step && value.tipologia == "domanda"){
                  return (<span className="indexPregunta selectedPregunta">{index +1}</span>)
                }else if(value.tipologia == "domanda"){
                  return (<span  className="indexPregunta">{index + 1 }</span>)
                }else{
                  return (<></>)
                }
            })}
         </p>

         <p className="testoDomanda" dangerouslySetInnerHTML={{__html: domande[step].domanda}}></p>
      </div>
      )
  }


  function renderSelezioni(){

    return (<div>
      <h2 class="titolo">{domande[step].domanda}</h2>
      <div className="row">
      {
        domande[step].selezioni.map((value,index)=>{
          
          return (
          
            <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 itemSelezioni" onClick={() => {setSelected(index)}}>
              <img src={value.img}/>
              <span className={domande[step].selezioni[index].selected ? ("descrizioneSelezioniSelected") : ("descrizioneSelezioni")}>{value.descrizione}</span>
              <span className={domande[step].selezioni[index].selected ? ("checkboxSelected visible") : ("checkboxSelected")}><FontAwesomeIcon icon={faCheckCircle} /></span>
            </div>
         
          )}
        )
      }
       </div>
    </div>)
  }


  function setSelected(qualeSelezioni){

    var temp = domande;
      temp[step].selezioni.map((item,index) => {
        if(index == qualeSelezioni){
          temp[step].selezioni[index].selected = !temp[step].selezioni[index].selected
        }
      })

      setDomande(temp)
      setStic(prev => prev+1)

  }

  

  function renderStepFinale(){
    return (
      <div>
         <img src={domande[step].img}/>
         <h4>{domande[step].titoletto}</h4>
         <p class="testofinale" dangerouslySetInnerHTML={{__html: domande[step].domanda}}></p>
      </div>
      )
  }

  function DomandaBody(){

    if(step == Object.keys(domande).length ){
       window.location.replace('/find'+query);
    }

    if(domande[step].tipologia == "domanda"){
      return renderDomanda()
    }
    else if(domande[step].tipologia == "selezioni"){
      return renderSelezioni()
    }
    else if(domande[step].tipologia == "finale"){
      return renderStepFinale()
    }
   
  }

  function StampaPulsanti(){
    if(domande[step].pulsanti == 2){
      return (<div className="contieniPulsanti">
        <Button onClick={() => { setStep(prev => prev+1) }}>{lang[locale][landing].no}</Button>
        <Button onClick={() => {setStep(prev => prev+1) }} variant="secondary">{lang[locale][landing].si}</Button> 
      </div>)
    }
    if(domande[step].pulsanti == 1 && domande[step].tipologia == "finale"){
      return (<div className="contieniPulsanti buttonStepFinale">
      <Button onClick={() => { window.location.replace(bottoneFinale); }} className="unico">{lang[locale][landing].avanti}</Button>
    </div>)
    }
    if(domande[step].pulsanti == 1 && domande[step].tipologia != "finale"){
      return (<div className="contieniPulsanti">
      <Button onClick={() => { setStep(prev => prev+1) }} className="unico">{lang[locale][landing].continua}</Button>
    </div>)
    }
  }
        

	return ( 
  <div className="lpBackground" style={{height: window.innerHeight}}>
   
    <Modal
        show={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="modale_landing lp1"
        onHide={() => {return } }
      >
  
        <Modal.Body>
            <div class="lpbody">
              <DomandaBody/>
             
            </div>
        </Modal.Body>
        <Modal.Footer>
           <StampaPulsanti/>
          </Modal.Footer>
      </Modal>
      </div>);


}